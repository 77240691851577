import React,{useState} from "react"
import { useMediaQuery } from "react-responsive"
import Header from "../Header"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer"
import "./index.css"

const clientsImages=[
    {
        id:100,
        image:"flutter.png"
    },
    {
        id:101,
        image:"flutter.png"
    },
    {
        id:102,
        image:"flutter.png"
    },
    {
        id:104,
        image:"flutter.png"
    },
    {
        id:105,
        image:"flutter.png"
    },
    {
        id:106,
        image:"flutter.png"
    },
    {
        id:107,
        image:"flutter.png"
    },
    {
        id:108,
        image:"flutter.png"
    },
    {
        id:109,
        image:"flutter.png"
    },
    {
        id:110,
        image:"flutter.png"
    },
    {
        id:111,
        image:"flutter.png"
    },
    {
        id:112,
        image:"flutter.png"
    },
]

const OurClients=()=>{
    const [loadClient,setLoadClient]=useState(0);
    const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
return(
     <>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className="our-clients-route-container-main">
    <div className="our-clients-route-container">
    <div className="our-clients-route-container-inner">
        <h1 className="our-clients-route-heading">To provide high-quality construction services that exceed our clients' expectations.</h1>
        <p className="our-clients-route-para">We focus on providing our clients with the best possible service. We use the latest technology and equipment to ensure that our projects are completed on time and within budget. We also have a team of highly skilled and experienced professionals who are dedicated to providing our clients with the best possible service.</p>
    </div>
    <img src={require('../InsuImages/our-client.png')} className="our-client-route-image" alt="" />
    </div>
    </div>
    <div className="our-clients-route-list-main-container">
        <h1 className="our-clients-route-head">Our Clients</h1>
        <p className="our-clients-route-para-2">We focus on providing our clients with the best possible service.</p>
        <div className="our-clients-route-list-container">
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/Prospect_Logo.png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/BUILDNSPACE CONSTRUCTION_FF2.png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/Cha_Ta_Logo_white.png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/CAMELIA_GROUP_OF_SCHOOL_LOGO_REDESIGN-1_prev_ui.png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/Cloudempire_Logo_prev_ui.png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/ACHARJYA PHARMACY_logo_prev_ui.png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/Tamrolipto_College_Of_Medical_Sciencee_Logo_WHITE_prev_ui.png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/Spot Boyz_Logo_recolor_prev_ui (1).png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/LBC LOGO_JPG_prev_ui.png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/Gift studio_Logo_prev_ui.png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/Dr Bedanka Roy_logo_prev_ui.png')} className="our-clients-route-list-image" alt="" />
            </div>
            <div className="our-clients-route-list-container-item">
                <img src={require('../InsuImages/Career_30Logo_white_prev_ui.png')} className="our-clients-route-list-image" alt="" />
            </div>
            {clientsImages.slice(0,loadClient).map((eachClient)=>(
                <div className="our-clients-route-list-container-item">
                <img src={require(`../InsuImages/${eachClient.image}`)} className="our-clients-route-list-image" alt="" />
            </div>
            ))}
        </div>
        <button type="button" className="see-more-button" onClick={()=>setLoadClient(loadClient+6)}>VIEW MORE</button>
    </div>
    <Footer />
    </>
)}
export default OurClients