import { IoIosMail,IoIosCall } from "react-icons/io";
import { FaFacebookF,FaWhatsapp, } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

import "./index.css"

const ContactUsLocation=()=>(
    <div className="contact-us-office-main-container">
        <span className="our-locations-contact-us">OUR LOCATIONS</span>
        <h1 className="our-offices-contact-us">OUR OFFICES</h1>
        <div className="contact-us-office-main-container-2">
            <div className="contact-us-office-locate-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705035343/qhjbpiwy0jhodc9k4nwg.png" className="our-offices-img" alt="our-offices-img" />
                <h1 className="office-location-head">Bolpur</h1>
                <p className="office-location-para">Headquarter</p>
                <div className="io-mail-contact-us">
                    <IoIosMail className="contact-us-office-main-icon" />
                    <p className="info-mail-para">info@insucreation.in</p>
                </div>
                <div className="io-mail-contact-us">
                    <IoIosCall className="contact-us-office-main-icon" />
                    <p className="info-mail-para">+91 9679985972</p>
                </div>
                <div className="contact-us-btn-ic">
                    <button type="button" className="contact-us-office-main-icon-btn"><FaFacebookF className="contact-us-office-main-icon btn-cont-2" /></button>
                    <button type="button" className="contact-us-office-main-icon-btn"><FaWhatsapp className="contact-us-office-main-icon btn-cont-2" /></button>
                    <button type="button" className="contact-us-office-main-icon-btn"><IoIosMail className="contact-us-office-main-icon btn-cont-2 btn-cont-3" /></button>
                    <button type="button" className="contact-us-office-main-icon-btn"><FaLocationDot className="contact-us-office-main-icon btn-cont-2" /></button>

                </div>
            </div>
            <div className="contact-us-office-locate-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705038567/xvyeqpzabttlx1xo67ga.png" className="our-offices-img" alt="our-offices-img" />
                <h1 className="office-location-head">Tamluk</h1>
                <p className="office-location-para">Registered Office</p>
                <div className="io-mail-contact-us">
                    <IoIosMail className="contact-us-office-main-icon" />
                    <p className="info-mail-para">info@insucreation.in</p>
                </div>
                <div className="io-mail-contact-us">
                    <IoIosCall className="contact-us-office-main-icon" />
                    <p className="info-mail-para">+91 9547361869</p>
                </div>
                <div className="contact-us-btn-ic">
                    <button type="button" className="contact-us-office-main-icon-btn"><FaFacebookF className="contact-us-office-main-icon btn-cont-2" /></button>
                    <button type="button" className="contact-us-office-main-icon-btn"><FaWhatsapp className="contact-us-office-main-icon btn-cont-2" /></button>
                    <button type="button" className="contact-us-office-main-icon-btn"><IoIosMail className="contact-us-office-main-icon btn-cont-2 btn-cont-3" /></button>
                    <button type="button" className="contact-us-office-main-icon-btn"><FaLocationDot className="contact-us-office-main-icon btn-cont-2" /></button>

                </div>
            </div>
            <div className="contact-us-office-locate-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705038617/vvevsslz3xr3ymd9ajud.png" className="our-offices-img" alt="our-offices-img" />
                <h1 className="office-location-head">Bardhaman</h1>
                <p className="office-location-para">Branch Office</p>
                <div className="io-mail-contact-us">
                    <IoIosMail className="contact-us-office-main-icon" />
                    <p className="info-mail-para">info@insucreation.in</p>
                </div>
                <div className="io-mail-contact-us">
                    <IoIosCall className="contact-us-office-main-icon" />
                    <p className="info-mail-para">+91 9547361869</p>
                </div>
                <div className="contact-us-btn-ic">
                    <button type="button" className="contact-us-office-main-icon-btn"><FaFacebookF className="contact-us-office-main-icon btn-cont-2" /></button>
                    <button type="button" className="contact-us-office-main-icon-btn"><FaWhatsapp className="contact-us-office-main-icon btn-cont-2" /></button>
                    <button type="button" className="contact-us-office-main-icon-btn"><IoIosMail className="contact-us-office-main-icon btn-cont-2 btn-cont-3" /></button>
                    <button type="button" className="contact-us-office-main-icon-btn"><FaLocationDot className="contact-us-office-main-icon btn-cont-2" /></button>

                </div>
            </div>
        </div>
    </div>
)

export default ContactUsLocation