import { useState } from "react";
import {Link} from "react-router-dom"
import { Icon } from '@iconify/react';
import { Sling as Hamburger } from 'hamburger-react'
import { IoClose,IoCallSharp } from "react-icons/io5";
import { FaAngleDown,FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { MdKeyboardArrowUp,MdOutlineSubdirectoryArrowRight } from "react-icons/md";
import AboutDropdown from '../AboutDropdown';
import ServicesDropdown from "../ServicesDropdown"
import logo from "../InsuImages/logo.png"

import "./index.css"
import DropDownContact from "../DropDownContact";

const Navbar=()=>{
    const [dropdown,setDropdown]=useState(false)
    const [dropdown2,setDropdown2]=useState(false)
    const [isOpen, setOpen] = useState(false)
    const [color,setColor]=useState(false)
    const [aboutMobileClick,setAboutMobileClick]=useState(false)
    const [servicesMobileClick,setServicesMobileClick]=useState(false)
    const [webClick,setWebClick]=useState(false)
    const [digitalClick,setDigitalClick]=useState(false)
    const [mobileAppClick,setMobileAppClick]=useState(false)
    const [contentClick,setContentClick]=useState(false)
    const [graphicClick,setGraphicClick]=useState(false)
    const [contactUsClick,setContactUs]=useState(false)
    const [dropdownContact,setDropdownContact]=useState(false)

    const onServicesMobileClick=()=>{ 
      setServicesMobileClick(!servicesMobileClick)
      setWebClick(false)
      setDigitalClick(false)
      setMobileAppClick(false)
      setContentClick(false)
      setGraphicClick(false)
    }
 
    const onContactClick=()=>{
      setContactUs(!contactUsClick)
    }

    const changeColor=()=>{
      if (window.scrollY>80){
        setColor(true)
      }
      else{
        setColor(false)
      }
    }
    
    window.addEventListener("scroll",changeColor)

    return  (
        <>
    <nav className="desktop-nav-v">
      <div className={color?"nav-main-container nav-main-container-2":"nav-main-container"}>
    <div className="nav-container">
      {color?
      <Link to="/">
        <div className="nav-logo-container">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705467975/dq6fffxfngksbidhqcz5.png" className="particle-nav-container" alt="particle-nav-container" />
        <h1 className="kreeti-technologies-heading other-page-color other-page-color-2 nav-color-link">Insu Creation</h1>
      </div></Link>: 
      <Link to="/">
        <img src={logo} className="particle-nav-container" alt="particle-nav-container" />
        </Link>
      }
      <ul className="list-container-navbar">
      <li className={color?"nav-link-list-item other-page-color":"nav-link-list-item"}>
        <div className='nav-link-item-container portfolio-nav'>
        HOME
        </div>
        </li>
      <li className={color?"nav-link-list-item other-page-color nav-scroll-about":"nav-link-list-item"} onMouseEnter={()=>setDropdown(true)} onMouseLeave={()=>setDropdown(false)}>
        <Link to="/aboutus" className={color?"nav-scroll-about":"nav-not-scroll"}>
        <div className='nav-link-item-container portfolio-nav serv-ss-s nav-link-list-item-mbn'>
        ABOUT US<Icon icon="bx:chevron-down" className="arrow-down-icon" />
        </div>
        {dropdown&&<AboutDropdown newDr1={color} />}
        </Link>
        </li>
        <li className={color?"nav-link-list-item other-page-color":"nav-link-list-item"} onMouseEnter={()=>setDropdown2(true)} onMouseLeave={()=>setDropdown2(false)}> 
        <div className='nav-link-item-container portfolio-nav serv-ss-s'>
        SERVICES<Icon icon="bx:chevron-down" className="arrow-down-icon" />
        </div>
        {dropdown2 && <ServicesDropdown newDr={color} />}
        </li>
        <li className="nav-link-list-item nav-link-about-us">
        <Link to="/portfolio"  className={color?"nav-scroll-about":"nav-not-scroll"}>
        <div className='nav-link-item-container portfolio-nav nav-link-list-item-mbn'>
        PORTFOLIO
        </div>
        </Link>
        </li>
        <li className="nav-link-list-item portfolio-nav nav-link-about-us">
        <Link to="/career" className={color?"nav-scroll-about":"nav-not-scroll"}>
        <div className='nav-link-item-container nav-link-list-item-mbn'>
        CAREERS
        </div>
        </Link>
        </li>
        <li className={color?"nav-link-list-item other-page-color nav-scroll-about":"nav-link-list-item"} onMouseEnter={()=>setDropdownContact(true)} onMouseLeave={()=>setDropdownContact(false)}>
        <Link to="/aboutus" className={color?"nav-scroll-about":"nav-not-scroll"}>
        <div className='nav-link-item-container portfolio-nav serv-ss-s nav-link-list-item-mbn'>
        CONTACT US<Icon icon="bx:chevron-down" className="arrow-down-icon" />
        </div>
        {dropdownContact&&<DropDownContact newDr1={color} />}
        </Link>
        </li>
      </ul>
    </div>
    </div>

  </nav>
  <nav className="mobile-cont-side-1">
   {isOpen &&
  <div className="mobile-cont-side-2">
    <div className="mobile-close-head-div">
      <h1 className="mobile-close-head">Close</h1>
      <IoClose size={25} onClick={()=>setOpen(!isOpen)} />
    </div>
    <ul className="mobile-nav-it-list-container">
      <li className="mobile-nav-it-list-item">
        <Link to="/" className="mobile-nav-it">
        Home
        </Link>
      </li>
      <hr />
      <li className="mobile-nav-it-list-item mobile-nav-it" onClick={()=>setAboutMobileClick(!aboutMobileClick)}>
        <div className="mobile-drop-down-it">
        About Us
        {aboutMobileClick?<MdKeyboardArrowUp />:<FaAngleDown />}
        </div>
      </li>
      {aboutMobileClick &&
      <ul className="mobile-about-click-ul">
        <li className="mobile-nav-it-list-item">
          <Link to="/aboutus" className="mobile-nav-it-click">
           <MdOutlineSubdirectoryArrowRight /> About Us
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/our-clients" className="mobile-nav-it-click">
           <MdOutlineSubdirectoryArrowRight /> Our Clients
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/technologiesweuse" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Technologies We Use
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/how-we-work" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> How We Work
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/why-trust-and-choose-us" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Why Trust And Choose Us?
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/our-partners" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Our Partners
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/terms-condition" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Terms And Conditions
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/payment-policy" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Payment Policy
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/privacy-policy" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Privacy Policy
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/refund-policy" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Refund Policy
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/partner-with-us" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Partner With Us
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/schedule-meeting" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Schedule a Meeting
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/site-map" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Site Map
          </Link>  
        </li>
      </ul>}
      <hr />
      <li className="mobile-nav-it-list-item mobile-nav-it" onClick={onServicesMobileClick}>
      <div className="mobile-drop-down-it">
        Services
        {servicesMobileClick?<MdKeyboardArrowUp />:<FaAngleDown />}
        </div>
      </li>
      {servicesMobileClick &&
      <ul className="mobile-about-click-ul">
        <li className="mobile-nav-it-list-item mobile-nav-it-click" onClick={()=>setWebClick(!webClick)}>
        <div className="mobile-drop-down-it mobile-nav-it-click-web">
        Web Development
        {webClick?<MdKeyboardArrowUp />:<FaAngleDown />}
        </div>
        </li>
        {webClick &&
        <ul className="mobile-about-click-ul mobile-web-click-ul">
        <li className="mobile-nav-it-list-item">
          <Link to="/frontend" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Frontend Web Development
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/wordPress" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> WordPress Development
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/backend" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Backend Web Development
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/php-development" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> PHP Development
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/shopify-developer" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Shopify Developer
          </Link>
        </li>
        
      </ul>}
        <li className="mobile-nav-it-list-item mobile-nav-it-click" onClick={()=>setDigitalClick(!digitalClick)}>
        <div className="mobile-drop-down-it mobile-nav-it-click-web">
        Digital Marketing
        {digitalClick?<MdKeyboardArrowUp />:<FaAngleDown />}
        </div>
        </li>
        {digitalClick &&
        <ul className="mobile-about-click-ul mobile-web-click-ul">
        <li className="mobile-nav-it-list-item">
          <Link to="/seo" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  SEO
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/social-media-marketing" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Social Media Marketing
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/digital-marketing" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Digital Marketing Funnel
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/local-seo-services" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Local SEO Services
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/technical-seo-services" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Technical SEO Services
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/google-analytics" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />   Google Analytics
          </Link>
        </li>
        
      </ul>}
        <li className="mobile-nav-it-list-item mobile-nav-it-click" onClick={()=>setMobileAppClick(!mobileAppClick)}>
        <div className="mobile-drop-down-it mobile-nav-it-click-web">
        Mobile App Development
        {mobileAppClick?<MdKeyboardArrowUp />:<FaAngleDown />}
        </div>
        </li>
        {mobileAppClick &&
        <ul className="mobile-about-click-ul mobile-web-click-ul">
        <li className="mobile-nav-it-list-item">
          <Link to="/react-native-development" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  React Native Development
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/flutter-development" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Flutter App Development
          </Link>
        </li>
      </ul>}
        <li className="mobile-nav-it-list-item mobile-nav-it-click" onClick={()=>setContentClick(!contentClick)}>
        <div className="mobile-drop-down-it mobile-nav-it-click-web">
        Content Development
        {contentClick?<MdKeyboardArrowUp />:<FaAngleDown />}
        </div>
        </li>
        {contentClick &&
        <ul className="mobile-about-click-ul mobile-web-click-ul">
        <li className="mobile-nav-it-list-item">
          <Link to="/landing-page-development" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Landing Page Development
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/article-writing" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Article Writing
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/social-media-content" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Social Media Content
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/video-ads-for-ecommerce" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Video Ads For E-commerce
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/product-description-writing" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Product Description Writing
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/blog-content-writing" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Blog Content Writing
          </Link>
        </li>
        
      </ul>}
        <li className="mobile-nav-it-list-item mobile-nav-it-click" onClick={()=>setGraphicClick(!graphicClick)}>
        <div className="mobile-drop-down-it mobile-nav-it-click-web">
        Graphic Design
        {graphicClick?<MdKeyboardArrowUp />:<FaAngleDown />}
        </div>
        </li>
        {graphicClick &&
        <ul className="mobile-about-click-ul mobile-web-click-ul">
        <li className="mobile-nav-it-list-item">
          <Link to="/graphic-design" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />   Graphic Design
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/complete-branding" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Complete Branding
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/logo-designing" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Logo Designing
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/product-packaging" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Product Packaging Design
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/business-presentation" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  Business Presentation
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/animation-vedio" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight />  2D & 3D Animation Videos
          </Link>
        </li>
        
      </ul>}
      </ul>}
      <hr />
      <li className="mobile-nav-it-list-item">
        <Link to="/portfolio" className="mobile-nav-it">
        Portfolio
        </Link>
      </li>
      <hr />
      <li className="mobile-nav-it-list-item">
        <Link to="/career" className="mobile-nav-it">
        Career
        </Link>
      </li>
      <hr />
      <li className="mobile-nav-it-list-item mobile-nav-it" onClick={onContactClick}>
      <div className="mobile-drop-down-it">
        Contact Us
        {contactUsClick?<MdKeyboardArrowUp />:<FaAngleDown />}
        </div>
      </li>
      {contactUsClick &&
      <ul className="mobile-about-click-ul">
        <li className="mobile-nav-it-list-item">
          <Link to="/contactus" className="mobile-nav-it-click">
           <MdOutlineSubdirectoryArrowRight /> Contact Us
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/partner-with-us" className="mobile-nav-it-click">
           <MdOutlineSubdirectoryArrowRight /> Partners With Us
          </Link>
        </li>
        <li className="mobile-nav-it-list-item">
          <Link to="/schedule-meeting" className="mobile-nav-it-click">
          <MdOutlineSubdirectoryArrowRight /> Schedule a Meeting
          </Link>
        </li>
        </ul>}
    </ul>
    <div className="sidebar-icons-main">
      <div className="sidebar-icons-main-d">
        <a href="https://www.facebook.com/insucreation" target="_blank"><FaFacebook className="side-bar-in" /></a>
      <a href="https://www.linkedin.com/company/insucreation/" target="_blank"><FaLinkedin className="side-bar-in" /></a>
      <a href="tel:9547361869"><IoCallSharp className="side-bar-in" /></a>
      </div>
    </div>
  </div>}
  <div className='mobile-nav-main-container'>
  <div className="mobile-nav-logo-container">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705467975/dq6fffxfngksbidhqcz5.png" className="mobile-nav-logo-img" alt="particle-nav-container" />
        <h1 className="mobile-nav-head-logo">Insu Creation</h1>
      </div>
  <Hamburger direction="right" size={23} toggled={isOpen} toggle={setOpen} />
  </div>
   </nav>
      </>
)
}
export default Navbar