import {HashRouter,Route,Switch} from "react-router-dom";
import { Helmet } from 'react-helmet';
import Home from "./components/Home"
import AboutUs from "./components/AboutUs"
import ContactUs from "./components/ContactUs";
import Portfolio from "./components/Portfolio";
import WebsiteDesignPortfolio from "./components/WebsiteDesignPortfolio";
import LogoDesignView from "./components/LogoDesignView";
import PortfolioPosterDesign from "./components/PortfolioPosterDesign";
import SeoDesign from "./components/SeoDesign";
import BrandingCollaterales from "./components/BrandingCollaterales";
import TechnologiesWeUse from "./components/TechnologiesWeUse";
import Technologies from "./components/Technologies";
import WordPressDevelopment from "./components/WordPressDevelopment";
import Backend from "./components/Backend";
import Seo from "./components/Seo"
import SocialMediaMarketing from "./components/SocialMediaMarketing";
import DigitalMarketing from "./components/DigitalMarketing";
import LocalSeo from "./components/LocalSeo";
import TechnicalSeoServices from "./components/TechnicalSeoServices";
import GoogleAnalytics from "./components/GoogleAnalytics";
import ReactNativeDevelopment from "./components/ReactNativeDevelopment";
import FlutterDevelopment from "./components/FlutterDevelopment";
import LandingPageDevelopment from "./components/LandingPageDevelopment";
import ArticleWriting from "./components/ArticleWriting";
import SocialMediaContent from "./components/SocialMediaContent";
import VideoAdsContent from "./components/VideoAdsContent";
import BlogsContentWriting from "./components/BlogsContentWriting";
import ProductDescriptionWriting from "./components/ProductDescriptionWriting";
import Canva from "./components/Canva";
import CompleteDesign from "./components/CompleteDesign";
import LogoDesigning from "./components/LogoDesigning";
import ProductPackagingDesign from "./components/ProductPackagingDesign";
import BusinessPresentation from "./components/BusinessPresentation";
import AnimationVedio from "./components/AnimationVedio";
import HowWeWork from "./components/HowWeWork";
import WhyTrustAndChooseUs from "./components/WhyTrustAndChooseUs";
import Career from "./components/Career";
import OurClients from "./components/OurClients";
import ProductPackagingPortfolio from "./components/ProductPackagingPortfolio";
import AppPortfolio from "./components/AppPortfolio";
import GraphicPortfolio from "./components/GraphicPortfolio";
import OurPartners from "./components/OurPartners";
import PaymentPolicy from "./components/PaymentPolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import RefundPolicy from "./components/RefundPolicy";
import PatnerWithUs from "./components/PatnerWithUs";
import ScheduleMeetingContainer from "./components/ScheduleMeetingComponent";
import SiteMap from "./components/SiteMap";
import SpotifyDeveloper from "./components/SpotifyDeveloper";
import PhpDevelopment from "./components/PhpDevelopment";



const App=()=>{
  
      return (
    <HashRouter>
         <Helmet>
            <title>Enrich Your Productivity with Insu Creation's Creativity: Elite Web/App Development, Graphic Design, and Innovative Digital Marketing | Expert in PHP, Python, Kotlin, NodeJS, ReactJS</title>
            <meta name="description" content="Enrich Your Productivity with Insu Creation's Creativity - Offering Elite Web/App Development, Graphic Design, Content Writing, and Innovative Digital Marketing. Expertise in PHP, Python, Kotlin, NodeJS, ReactJS. Your one-stop digital solution partner globally." />
            <meta name="keywords" content="Insu Creation, Web Development, App Development, Graphic Design, Content Writing, Digital Marketing, PHP, Python, Kotlin, NodeJS, ReactJS, Innovative Solutions, Global Partner, Creative Services" />
        </Helmet>
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/aboutus" component={AboutUs} />
        <Route exact path="/contactus" component={ContactUs} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/webdesigns" component={WebsiteDesignPortfolio} />
        <Route exact path="/logodesigns" component={LogoDesignView} />
        <Route exact path="/posterdesigns" component={PortfolioPosterDesign} />
        <Route exact path="/seodesigns" component={SeoDesign} />
        <Route exact path="/branding-collaterales" component={BrandingCollaterales} />
        <Route exact path="/technologiesweuse" component={TechnologiesWeUse} />
        <Route exact path="/frontend" component={Technologies} />
        <Route exact path="/wordpress" component={WordPressDevelopment} />
        <Route exact path="/backend" component={Backend} />
        <Route exact path="/seo" component={Seo} />
        <Route exact path="/social-media-marketing" component={SocialMediaMarketing} />
        <Route exact path="/digital-marketing" component={DigitalMarketing} />
        <Route exact path="/local-seo-services" component={LocalSeo} />
        <Route exact path="/technical-seo-services" component={TechnicalSeoServices} />
        <Route exact path="/google-analytics" component={GoogleAnalytics} />
        <Route exact path="/react-native-development" component={ReactNativeDevelopment} />
        <Route exact path="/flutter-development" component={FlutterDevelopment} />
        <Route exact path="/landing-page-development" component={LandingPageDevelopment} />
        <Route exact path="/article-writing" component={ArticleWriting} />
        <Route exact path="/social-media-content" component={SocialMediaContent} />
        <Route exact path="/video-ads-for-ecommerce" component={VideoAdsContent} />
        <Route exact path="/blog-content-writing" component={BlogsContentWriting} />
        <Route exact path="/product-description-writing" component={ProductDescriptionWriting} />
        <Route exact path="/graphic-design" component={Canva} />
        <Route exact path="/complete-branding" component={CompleteDesign} />
        <Route exact path="/logo-designing" component={LogoDesigning} />
        <Route exact path="/product-packaging" component={ProductPackagingDesign} />
        <Route exact path="/business-presentation" component={BusinessPresentation} />
        <Route exact path="/animation-vedio" component={AnimationVedio} />
        <Route exact path="/how-we-work" component={HowWeWork} />
        <Route exact path="/why-trust-and-choose-us" component={WhyTrustAndChooseUs} />
        <Route exact path="/career" component={Career} />
        <Route exact path="/our-clients" component={OurClients} />
        <Route exact path="/product-packaging-portfolio" component={ProductPackagingPortfolio} />
        <Route exact path="/app-portfolio" component={AppPortfolio} />
        <Route exact path="/graphic-portfolio" component={GraphicPortfolio} />
        <Route exact path="/our-partners" component={OurPartners} />
        <Route exact path="/payment-policy" component={PaymentPolicy} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-condition" component={TermsAndConditions} />
        <Route exact path="/refund-policy" component={RefundPolicy} />
        <Route exact path="/partner-with-us" component={PatnerWithUs} />
        <Route exact path="/schedule-meeting" component={ScheduleMeetingContainer} />
        <Route exact path="/site-map" component={SiteMap} />
        <Route exact path="/shopify-developer" component={SpotifyDeveloper} />
        <Route exact path="/php-development" component={PhpDevelopment} />
    </Switch> 
    </HashRouter>
) 
      }

    
export default App