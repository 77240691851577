import {Helmet} from "react-helmet"
import { useMediaQuery } from "react-responsive"
import Header from "../Header"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer"

import "./index.css"

const TechnologiesWeUse=()=>{

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
return (
    <>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <Helmet>
    <title>Insu Creation: Crafting Excellence with PHP, Python, Kotlin, NodeJS, ReactJS</title>
    <meta name="description" content="Unlocking Innovation with Insu Creation: Explore our world of digital possibilities with expertise in PHP, Python, Kotlin, NodeJS, and ReactJS. We blend creativity and technology to craft unique digital experiences for your success." />
    <meta name="keywords" content="Insu Creation, Web Development, App Development, Graphic Design, Content Writing, Digital Marketing, PHP, Python, Kotlin, NodeJS, ReactJS, Innovative Solutions, Technology Expertise, Creative Services, Global Partner" />

    </Helmet>
    <div className="technologies-we-use-route-con-main">
        <div className="technologies-we-use-route-con">
            <div className="our-technologies-stack-main-container">
            <div className="mobile-view-cutting-head">
                <h1 className="our-technologies-stack mobile-div-head-design">Cutting-edge Tools & Frameworks</h1>
                <p className="our-technologies-stack-para">Crafting excellence demands a blend of precision, passion, and state-of-the-art tools. At Insu Creation, we infuse each task with meticulous care, ensuring our clients receive unparalleled solutions that redefine industry standards.</p>
                <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
                </button>
            </div>
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705474637/ay3ufjp6bmllhtujbskr.png" className="our-tech-img" alt="our-tech-img" />
            </div>
        </div>
        <div className="technologies-we-use-design-con-main">
    <div className="technologies-we-use-design-con">
    <h1 className="portfolio-head-rote mobile-div-head-design">Design</h1>
    <p className="portfolio-para-rote poster-des-para design-des-para">From conceptualizing ideas to breathing life into designs, our creative process is powered by a suite of design tools that transform concepts into captivating realities.</p>
    <div className="technologies-we-use-design-con-main">
    <div className="tech-use-img-container-main">
  <div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705472141/lakb5xa5vcbxfjytihvo.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705476031/rfxbcs30joi6uh2ne13c.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705476074/fzzttduh3pc1bgipxxxi.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705476137/wom0eyuau4nx1mr7wm3l.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705476208/uad6ahzerta1nq5rvdyx.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705476249/kigeuzvtms4mzvh8xu90.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705476288/zey6qrppqs6f89qeq1j7.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705476331/eqkrgr5adlkouvkrcc43.png" className="tech-use-img" alt="" />
</div>
</div>
</div>
</div>
 <div className="technologies-we-use-design-con">
    <h1 className="portfolio-head-rote mobile-div-head-design">Database</h1>
    <p className="portfolio-para-rote poster-des-para design-des-para design-des-para-2">Your data deserves a secure and accessible home. After extensive research and thoughtful consideration, we've selected the ideal database platforms to host your valuable information, ensuring both accessibility and security.</p>
    <div className="technologies-we-use-design-con-main">
    <div className="tech-use-img-container-main">
  <div className="tech-use-img-container tech-use-img-container-2 mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705482826/ky6t9th8vu9ksz0xcux2.png" className="tech-use-img-2" alt="" />
</div>
<div className="tech-use-img-container tech-use-img-container-2 mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705482891/swzm59herh2xmqff9ml4.png" className="tech-use-img-2" alt="" />
</div>
<div className="tech-use-img-container tech-use-img-container-2 mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705482930/cmstjbe1r5aiwuyuzooh.png" className="tech-use-img-2" alt="" />
</div>
</div>
</div>
    </div>
    <div className="technologies-we-use-design-con">
    <h1 className="portfolio-head-rote mobile-div-head-design">Cloud</h1>
    <p className="portfolio-para-rote poster-des-para design-des-para design-des-para-2">Fuel the dynamic growth of your business without breaking the bank. Experience the advantages of scalability, flexibility, and cost-effectiveness through the cloud, making business expansion both feasible and efficient.</p>
    <div className="technologies-we-use-design-con-main">
    <div className="tech-use-img-container-main">
  <div className="tech-use-img-container tech-use-img-container-2 cloud-mobile-img mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705483215/n8oxpog3zv3cbfusttwv.png" className="tech-use-img-3" alt="" />
</div>
<div className="tech-use-img-container tech-use-img-container-2 cloud-mobile-img mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705483268/e0sibrvhzg5a5sqnqvvy.png" className="tech-use-img-3" alt="" />
</div>
<div className="tech-use-img-container tech-use-img-container-2 cloud-mobile-img mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705483305/xy4ux9zfabznqj2gxcjv.png" className="tech-use-img-3" alt="" />
</div>
</div>
</div>
    </div>
    <div className="technologies-we-use-design-con technologies-we-use-design-con-2">
    <h1 className="portfolio-head-rote mobile-div-head-design">App Technologies</h1>
    <p className="portfolio-para-rote poster-des-para">Creating web and mobile apps demands a unique skill set. Our carefully chosen technologies empower us with these capabilities, enabling us to deliver exceptional solutions in the digital landscape.</p>
    <div className="technologies-we-use-design-con-main">
    <div className="tech-use-img-container-main">
  <div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484492/t66qlwzwbdkuvlpactkr.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484535/omnllgqqej7ktj5mkzpl.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484573/abkafrlj1nqlzvvryzdv.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484608/gxn7zzntc2ekgwcodn63.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484644/b9cwwgnxefhcpwpfjuur.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484681/j417nqttrdhiwktha95c.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484716/waqcvam0dtgk5wegp831.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484748/drkq4r7lhllmnqclq7kx.png" className="tech-use-img" alt="" />
</div>
</div>
</div>
</div>
<div className="technologies-we-use-design-con  technologies-we-use-design-con-2">
    <h1 className="portfolio-head-rote mobile-div-head-design">Web Technologies</h1>
    <p className="portfolio-para-rote poster-des-para">Crafting user-friendly websites that fulfill their intended purpose hinges on employing the ideal technology stack. At Insu Creation, we leverage the perfect technology stack to ensure seamless user experiences and successful outcomes.</p>
    <div className="technologies-we-use-design-con-main">
    <div className="tech-use-img-container-main tech-use-img-container-main-2">
  <div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484811/abcfs23xhbeicsweusjc.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484847/p3k9yelwvlenedk9oh8y.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484883/z0p4fsxlfmbyyw9qcxuc.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484917/b5wa4wqa8voijrmz4yck.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484965/fefchnywl2olfstgukhx.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705484998/twkrulaut9mvipyhojks.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705485036/skyd59p3kk6fstjpmsm5.png" className="tech-use-img-3" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705485082/yjciflsb7ocsh2hwnfnf.png" className="tech-use-img-3" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705485166/vjkvgtepcryewoy2yxx7.png" className="tech-use-img" alt="" />
</div>
<div className="tech-use-img-container mobile-vi-tech-con-div">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705485222/m7ftzkivy6qlbubsdvve.png" className="tech-use-img-3" alt="" />
</div>
</div>
</div>
</div>
<div className="technologies-we-use-design-con technologies-we-use-design-con-2">
    <h1 className="portfolio-head-rote mobile-div-head-design">Hosting</h1>
    <p className="portfolio-para-rote poster-des-para">Your apps and websites need to operate 24/7, 365 days a year. We rely on these server technologies and platforms to guarantee maximum uptime, ensuring uninterrupted performance for your business.</p>
    <div className="hosting-div-platform-con">
    <div className="tech-use-img-container tech-use-img-container-hosting c-panel-h-panel">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705485720/ngl2ucvsyuqx81bha8sv.png" className="tech-use-img-4" alt="" />
</div>
<div className="tech-use-img-container tech-use-img-container-hosting c-panel-h-panel">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1707137902/mydggvhechmal0zbydcn.png" className="tech-use-img-4 c-panel-h-panel-img" alt="" />
</div>
</div>
    </div>
    </div>
</div>
    <Footer />
</>
)
}
export default TechnologiesWeUse