import React,{useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import Tilt from 'react-parallax-tilt';
import DetailsForm from '../DetailsForm';
import Header from "../Header"
import Navbar from '../Navbar';
import NavbarInOtherPage from "../NavbarInOtherPage"
import Footer from '../Footer';
import "./index.css"

const portfolioList=[
    {
        id:"0001",
        image:"Chunw",
    },
    {
        id:"0003",
        image:"Chung",
    },
    {
        id:"0004",
        image:"as",
    },
    {
        id:"0005",
        image:"KIS",
    },
    {
        id:"0006",
        image:"cafecheers",
    },
    {
        id:"0007",
        image:"Ivory_Dental",
    },
    {
        id:"0008",
        image:"Mom_Chocolate",
    },
    {
        id:"0009",
        image:"Sai_Dental",
    }  
]

const PortfolioPosterDesign=()=>{
    const [loadPoster,setLoadPoster]=useState(0);
    const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })

    return (
    <> 
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='poster-design-main-container-route'> 
    <span className="our-locations-contact-us our-wk poster-des-span">WE DELIVER OUR BEST</span>
    <h1 className="portfolio-head-rote mob-web-port-v">Poster Portfolio</h1>
    <p className="portfolio-para-rote poster-des-para mob-web-port-v-para">There are 6 different types of logos and Insu Creation provides unique designing in any logo type based on your requirements.</p>
    <div className='posters-main-con'>
    <Tilt className='tilt-co mob-web-port-v-con-pp'>
        <img src={require('../InsuImages/biryani.jpg')} className='poster-des' alt="poster-des" />
    </Tilt>
    <Tilt className='tilt-co mob-web-port-v-con-pp'>
        <img src={require('../InsuImages/moms.jpg')} className='poster-des' alt="poster-des" />

    </Tilt>
    <Tilt className='tilt-co mob-web-port-v-con-pp'>
     
        <img src={require('../InsuImages/Sanaka.jpg')} className='poster-des' alt="poster-des" />
     
    </Tilt>
    <Tilt className='tilt-co mob-web-port-v-con-pp'>
      
        <img src={require('../InsuImages/Insul.jpg')} className='poster-des' alt="poster-des" />
     
    </Tilt>
    <Tilt className='tilt-co mob-web-port-v-con-pp'>
     
        <img src={require('../InsuImages/thechu.jpg')} className='poster-des' alt="poster-des" />
     
    </Tilt>
    <Tilt className='tilt-co mob-web-port-v-con-pp'>
     
        <img src={require('../InsuImages/aswamedh.png')} className='poster-des' alt="poster-des" />
     
    </Tilt>
    <Tilt className='tilt-co mob-web-port-v-con-pp'>
      
        <img src={require('../InsuImages/Chunw_wah.jpg')} className='poster-des' alt="poster-des" />
     
    </Tilt>
    <Tilt className='tilt-co mob-web-port-v-con-pp'>
     
     <img src={require('../InsuImages/Hotel_DC.jpg')} className='poster-des' alt="poster-des" />
  
 </Tilt>
    {portfolioList.slice(0,loadPoster).map((eachIt)=>(
        <Tilt className='tilt-co mob-web-port-v-con-pp'>
            <img src={require(`../InsuImages/${eachIt.image}.png`)} className='poster-des' alt={eachIt.id} />
        </Tilt>
    ))
    }
    </div>
    <button type="button" className='click-to-load-more' onClick={()=>setLoadPoster(loadPoster+4)}>Click to load more</button>
    </div>
    <Footer />
    </>
)}

export default PortfolioPosterDesign