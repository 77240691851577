import {useState} from "react"
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useMediaQuery } from 'react-responsive'
import "./index.css";

const  TestimonialsContainer=()=> {
	const [readMore1,setReadMore1]=useState(false)
	const [readMore2,setReadMore2]=useState(false)
	const [readMore3,setReadMore3]=useState(false)
	const [readMore4,setReadMore4]=useState(false)

	  const settings = {
		dots: false,
		speed: 800,
		autoplay: true,
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 1,
	  }
	  const settingsMob = {
		dots: false,
		speed: 800,
		autoplay: true,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
	  }
	  const mediaQueryCondition=useMediaQuery({ query: '(max-width:767px)' })
	  const mediaQueryConditionT=mediaQueryCondition?settingsMob:settings
	  return (
		<div className='testmonials-container-web'>
			<h1 className='text-radius text-light text-animation bg-b services-testimonials-head'>SERVICE TESTIMONIALS</h1>
			<p className='client-testimonals-para'>Client Testimonials</p>
			<p className='check-out-what-para'>Check out what our happy customers say.</p>
			<div className='slider-con'>
		<Slider {...mediaQueryConditionT}>
		  <div>
			  <div className='testimonals-con-main'>
				<div className='testimonals-logo-cont'>
					<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
					<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690583/v8106anbsnhar5pwgkyb.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
				</div>
				<p className='testimonals-card-para'>I recently had the pleasure of working with Insu Creation for my website design, and the experience exceeded all expectations. From the initial consultation to the final launch, their team {readMore1 && "demonstrated unparalleled professionalism, creativity, and technical expertise..."}<span className='testimonals-span-para' onClick={()=>setReadMore1(!readMore1)}>{readMore1?"Read Less":"Read more"}</span>
				</p>
			  <div className='testimonals-name-div-cont'>
				<h1 className='testimonals-name-head'>Prasant Singh</h1>
				<p className='testimonals-role-para'>Senior Manager, Autonext</p>
				<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
			  </div>
			  </div>
		  </div>
		  <div>
		  <div className='testimonals-con-main'>
				<div className='testimonals-logo-cont'>
					<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
					<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704692606/cdyp2u7wakrhmmheucbn.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
				</div>
				<p className='testimonals-card-para'>I highly recommend Insu Creation to anyone in need of a website designer. Their creativity, technical proficiency, and customer-centric approach make them a standout choice in the industry...{readMore2 && "Thank you, Insu Creation, for bringing my vision to life!!"}<span className='testimonals-span-para' onClick={()=>setReadMore2(!readMore2)}>{readMore2?"Read Less":"Read More"}</span>
				</p>
			  <div className='testimonals-name-div-cont'>
				<h1 className='testimonals-name-head'>M. Roy</h1>
				<p className='testimonals-role-para'>Convener, DRDO</p>
				<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
			  </div>
			  </div>
		  </div>
		  <div>
		  <div className='testimonals-con-main'>
				<div className='testimonals-logo-cont'>
					<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
					<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704692832/lr28p78yb4jixpzdih4z.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
				</div>
				<p className='testimonals-card-para'>What truly sets Insu Creation apart is their dedication to customer satisfaction. They were responsive to my feedback, making revisions..{readMore3 && "promptly and ensuring that every aspect of the website met my requirements..."}<span className='testimonals-span-para' onClick={()=>setReadMore3(!readMore3)}>{readMore3?"Read Less":"Read more"}</span>
				</p>
			  <div className='testimonals-name-div-cont'>
				<h1 className='testimonals-name-head'>Sujit Jha</h1>
				<p className='testimonals-role-para'>Director, Legalsalah</p>
				<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
			  </div>
			  </div>
			  </div>
			  <div>
			  <div className='testimonals-con-main'>
				<div className='testimonals-logo-cont'>
					<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
					<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704693103/tkpqjhqezatsurrputzm.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
				</div>
				<p className='testimonals-card-para'>
I had an outstanding experience working with Insu Creation! Their web development expertise is truly commendable. The team demonstrated a high level of professionalism...{readMore4 && "delivering a website that not only met but exceeded my expectations"}<span className='testimonals-span-para' onClick={()=>setReadMore4(!readMore4)}>{readMore4?"Read Less":"Read More"}</span>
				</p>
			  <div className='testimonals-name-div-cont'>
				<h1 className='testimonals-name-head'>Akash Bansal</h1>
				<p className='testimonals-role-para'>Director, BansalPolymers</p>
				<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
			  </div>
			  </div>
			  </div>
		</Slider>
		</div>
		</div>
	  )
	}

	export default TestimonialsContainer