import { useMediaQuery } from "react-responsive"
import Header from "../Header"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer"
import "./logodesignview.css"

const LogoDesignView=()=>{
  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
return(
   <> 
<Header />
{mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
<DetailsForm />
<div className="logo-design-main-page-route">
<span className="our-locations-contact-us our-wk our-wk-2">WE DELIVER OUR BEST</span>
    <h1 className="portfolio-head-rote mob-web-port-v">Logo Portfolio</h1>
    <p className="portfolio-para-rote mob-web-port-v-para">There are 6 different types of logos and Insu Creation provides unique designing in any logo type based on your requirements.</p>
<div className="logo-page-route-list-container">
  <img src={require('../InsuImages/insuprinting.jpg')} className="logo-page-route-imag" alt="an old car" />
  <img src={require('../InsuImages/newStar.jpg')} className="logo-page-route-imag" alt="an old car" />
  <img src={require('../InsuImages/CareerI.jpg')} className="logo-page-route-imag" alt="an old car" />
  <img src={require('../InsuImages/ASWAMWDH.jpg')} className="logo-page-route-imag" alt="an old car" />
  <img src={require('../InsuImages/Tagore.jpg')} className="logo-page-route-imag" alt="an old car" />
  <img src={require('../InsuImages/Joyguru.jpg')} className="logo-page-route-imag" alt="an old car" />
  <img src={require('../InsuImages/LBC.jpg')} className="logo-page-route-imag" alt="an old car" />
  <img src={require('../InsuImages/BiryanI.png')} className="logo-page-route-imag" alt="an old car" />
  <img src={require('../InsuImages/eparebangla.jpg')} className="logo-page-route-imag" alt="an old car" />
  <img src={require('../InsuImages/whisperingoods.jpg')} className="logo-page-route-imag" alt="an old car" />
  </div>
</div>

<Footer />
</>
)}

export default LogoDesignView