import { useMediaQuery } from "react-responsive"
import Header from "../Header"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import ContactUsForm from "../ContactUsForm"
import {Helmet} from "react-helmet"
import ContactUsLocation from "../ContactUsLocation"
import Footer from "../Footer"
import "./index.css"

const ContactUs=()=>{
    const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })

    return (

    <div>
        <Helmet>
        <title>Connect with InsuCreation: Reach Out for Professional Guidance and Support</title>
<meta name="description" content="Contact InsuCreation today for expert assistance and personalized solutions. Our team is ready to address your inquiries, provide professional guidance, and offer support tailored to your needs. Reach out now to start the conversation."/>
<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

        </Helmet>
        <Header />
        {mediaQueryCondition?<Navbar />:<NavbarInOtherPage className="navbar-in-contact" />}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.539774264348!2d87.86263567427707!3d22.257534044446164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02958e16f747b7%3A0xf3745968222b54ff!2sInsu%20Creation!5e0!3m2!1sen!2sin!4v1707569593199!5m2!1sen!2sin" title="map" className="map-container-con" width="100%" height="600" style={{'border':'0;'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <ContactUsForm />
        <ContactUsLocation />
        <Footer />
    </div>
)}

export default ContactUs